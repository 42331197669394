import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'

const NavBar = ({ toggleNavbar, isActive }) => (
  <StaticQuery
    query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
    render={data => (
      <nav className='navbar is-fixed-top is-spaced' aria-label='main navigation' role='navigation'>
        <div className='container'>
        <div className='navbar-brand'>
          <Link to='/' className='navbar-item'>
            <span className="logo title is-4">Locumago</span>
          </Link>
          
          <a role='button' className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
            aria-expanded = 'false'
            data-target='navMenu'
            onClick={toggleNavbar}>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
        </div>

        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id='navMenu'>
          <div className='navbar-end'>
            <Link className='navbar-item' to='/'>Home</Link>
            <Link className='navbar-item' to='/employers'>Employers</Link>
            <Link className='navbar-item' to='/rural-community'>Rural Community</Link>
            <Link className='navbar-item' to='/investors'>Investors</Link>
            <Link className='navbar-item' to='/recruitment-agencies'>Recruitment Agencies</Link>
            <Link className='navbar-item' to='/blog'>Blog</Link>

            <div className='navbar-item'>
              <div className='field is-grouped'>
                <p className='control'>
                  <Link
                    className='button is-primary is-outlined'
                    to='/contact'>Contact Us</Link>
                </p>
              </div>
            </div>
          </div>
        </div>     
        </div>   
      </nav>
    )}
  />
)

export default NavBar

module.exports = {
  siteTitle: 'Locumago', // Site title.
  siteTitleAlt: 'Locumago', // Alternative site title for SEO.
  siteLogo: '/img/locumago-logo.png?v=1', // Logo used for SEO and manifest.
  siteUrl: 'https://locumago.com.au', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. 
  siteDescription: 'Find your locum pharmacists across Australia', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '147084616413753', // FB Application ID for using app insights
  disqusShortname: 'locumago', // Disqus shortname.
  userName: 'locumago',
  userTwitter: 'locumago',
  userLocation: 'Victoria. Australia',
  userDescription: '',
  copyright: 'Copyright © Locumago', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}

import React from 'react'
import config from '../../../config'
import { Link } from 'gatsby'

import {
  FaRegEnvelope, FaTwitter, FaFacebookF
} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='columns is-variable is-8-widescreen is-3-desktop is-2-tablet is-0-mobile'>
          <div className='column is-6'>
            <div>
              <div>
                <a className='logo title is-4' href={config.siteUrl} title={config.siteTitle}>Locumago</a>
              </div>

              <div className='about'>
                <p className='mt-1 is-size-6 has-text-justified has-text-weight-light'>
                  Locumago understands the perks of getting your perfect locum jobs whether you are a pharmacist, a doctor, optometrist, dentist, nurse or any other practitioners. The hard work that you do to get all the jobs suited for you will just be right here in one click away.
                </p>
              </div>
            </div>
          </div>
          <div className='column is-3'>
            <ul className='mt-1 links'>
                <ul><li><Link to='/' title='Home'><span>Home</span></Link></li></ul>
                <ul><li><Link to='/employers' title='Employers'><span>Employers</span></Link></li></ul>
                <ul><li><Link to='/blog' title='Blog'><span>Blog</span></Link></li></ul>
                <ul><li><Link to='/contact' title='Contact'><span>Contact</span></Link></li></ul>
            </ul>
          </div>
          <div className='column is-3'>
            <ul className='mt-1 links'>
                <ul><li><Link to='/rural-community' title='Rural Community'><span>Rural Community</span></Link></li></ul>
                <ul><li><Link to='/investors' title='Investors'><span>Investors</span></Link></li></ul>
                <ul><li><Link to='/recruitment-agencies' title='Recruitment Agencies'><span>Recruitment Agencies</span></Link></li></ul>
                <ul><li><a href='mailto:info@locumago.com' target='_blank' title='Email Locumago'><span>Email us</span></a></li></ul>
            </ul>
          </div>
        </div>
        
        <hr></hr>

        <div className='footer-bottom has-text-centered'>
          {config.copyright} {new Date().getFullYear()}, <a href={config.siteUrl} title={config.siteTitle}>Locumago</a>. All rights reserved.
        </div>

        
      </div>
    </footer>
  )
}

export default Footer
